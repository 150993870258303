import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import whitedeck from "../../assets/images/homepage/fuzzlets/Deck_White.png"
import heroBg from "../../assets/images/homepage/fuzzlets/background.png"
import cards from "../../assets/images/homepage/fuzzlets/cards.png"
import Button3d from "../../components/Buttons/Button3d"
import MinistudioLogo from "../../components/ImagePresets/MinistudioLogo"
import ResponsiveContainer from "../../components/Wrapper/ResponsiveContainer"

export default function FuzzletsBlock({
  onKickstarter
}: {
  onKickstarter: () => void
}) {
  return (
    <Box
      position="relative"
      h="100vh"
      minH="fit-content"
      w="100%"
      bg={["transparent", "transparent", "transparent", "transparent"]}
    >
      <Image
        src={heroBg}
        h={["100%", "100%", "100%", "100%"]}
        w="100%"
        objectFit="cover"
        position="absolute"
        objectPosition="bottom"
        top="0"
        left="0"
        zIndex={-1}
      />
      {/* <Box
        display={["block", "block", "block", "none"]}
        position="absolute"
        top="50%"
        left="0"
        w="100%"
        height="50%"
        bg="linear-gradient(180deg, rgba(35, 27, 42, 0.00) 2.08%, #191321 17.71%)"
      /> */}
      {/* <Box
        display={["none", "none", "none", "block"]}
        position="absolute"
        bottom="0"
        left="0"
        w="100%"
        height="20%"
        bg="linear-gradient(180deg, rgba(35, 27, 42, 0.00) 2.08%, #191321 67%)"
      /> */}
      <ResponsiveContainer
        p="0!important"
        position="relative"
        //width={["100%", "100%", "100%", "1000px", "1200px", "1400px"]}
        width={["100%", "100%", "100%", "100%"]}
        display="flex"
        flexDir="column"
      >
        <Flex w="100%" justifyContent="center">
          <MinistudioLogo
            mt={["2rem", "2rem", "4rem"]}
            variant="white"
            height={["60px", "60px", "100px"]}
            width=""
            ml={["1em", "1em", "3em", "5em", "10rem"]}
            mr="auto"
            mb="2em"
          />
        </Flex>
        <Box
          mt={["auto", "auto", "auto", "50px", "150px", "200px"]}
          mb="0"
          zIndex="1"
        >
          <Heading
            color="white"
            textAlign="center"
            fontSize={["26px", "32px", "32px", "40px", "50px", "60px"]}
          >
            Our first game is now live <br />
            on Kickstarter 
          </Heading>
          <Text
            color="white"
            textAlign="center"
            mt="1rem"
            fontSize={["14px", "16px", "16px", "20px", "24px", "28px"]}
          >
            The first personalized card game for kids is here. <br />
            Create your characters & play with them! <br />
            Available only until December 1st
          </Text>
          <Flex w="100%" justifyContent="center" gap="1rem" mt="1rem" display={["flex", "flex", "flex", "hidden"]}>
            <Image
              src={cards}
              w={["250px", "200px", "175px", "auto"]}
              margin="0"
              padding="0"
            />
          </Flex>
          <Flex w="100%" justifyContent="center" gap="2rem" mt="1rem">
            {/* <Box
              display={["none", "none", "none", "block"]}
              position="absolute"
              overflow="hidden"
              left="0"
              bottom="0"
            >
              <Image
                src={whitedeck}
                w={["250px", "200px", "175px", "auto"]}
              />
            </Box> */}
            <Button3d
              backgroundColor="#05CF78"
              color="#FFFFFF"
              bg3dColor="#007140"
              size="lg"
              onClick={onKickstarter}
              fontSize={["14px", "16px", "16px", "20px", "24px", "28px"]}
            >
              Discover the Fuzzlets on Kickstarter
            </Button3d>
            {/* <Box
              display={["none", "none", "none", "block"]}
              position="absolute"
              overflow="hidden"
              right="0"
              bottom="0"
            >
              <Image
                src={cards}
                w={["250px", "200px", "175px", "auto"]}
              />
            </Box> */}
          </Flex>
          <Flex w="100%" justifyContent="center" mt="1em" display={["flex", "flex", "flex", "hidden"]}>
            <Image
              src={whitedeck}
              bottom="0"
              w={["250px", "200px", "175px", "auto"]}
            />
          </Flex>
        </Box>
        <Box
          w="100%"
          h="100%"
          position="absolute"
          top="0"
          left="0"
          overflow="hidden"
          display={["none", "none", "none", "block"]}
        >
          <Image
            bottom={["65%", "65%", "60%", 0]}
            transform={[
              "translateY(50%)",
              "translateY(50%)",
              "translateY(50%)",
              "none",
            ]}
            src={whitedeck}
            objectFit="contain"
            objectPosition="top"
            position="absolute"
            left="-32px"
            w={["175px", "200px", "250px", "auto"]}
          />
          <Image
            bottom={["60%", "60%", "55%", 0]}
            transform={[
              "translateY(50%)",
              "translateY(50%)",
              "translateY(50%)",
              "none",
            ]}
            src={cards}
            objectFit="contain"
            objectPosition="top"
            position="absolute"
            right={["-2rem", "-2rem", "-2rem", 0]}
            w={["175px", "200px", "250px", "auto"]}
          />
        </Box>
      </ResponsiveContainer>
    </Box>
  )
}
