/// <reference types="@types/segment-analytics" />

import React, { createContext, useContext } from "react"
import Segment from "react-segment-analytics"
import type { Traits } from "react-segment-analytics"
import { SEGMENT_WRITE_KEY } from "../presentation/components/Creations/GlobalSettings"
import UserData from "../data/models/UserData"

interface Event {
  name:
    | "Try Our App"
    | "Login"
    | "Sign Up Part 1"
    | "Sign Up Part 2"
    | "Onboarding Done"
    | "Ask CamCam"
    | "CamCam guess right"
    | "CamCam was wrong"
    | "Succeeded to buy credits"
    | "Share"
    | "Get Your Free Star"
    | "Start the adventure"
    | "Continue the adventure"
    | "Next Panels"
    | "Previous Panels"
    | "Finish Webtoon"
    | "Click on a story"
    | "I want this one"
    | "Logout"
    | "Upload drawing sketch quest"
    | "Click on a squetch quest"
    | "Swipe to next creation of Sketch Quest"
    | "Sketch Quest Upload"
    | "Chat with CamCam"
    | "Click on chat suggestion"
    | "Speech to text"
    | "Stop text to speech"
    | "Play text to speech"
    | "Mute text to speech"
    | "Unmute text to speech"
    | "Add to cart print shop"
    | "Kickstarter"
  properties: {
    [key: string]: string | number | boolean | null
  }
}

interface ContextProps {
  load: () => void
  identify: (userId: UserData["id"], traits: Traits) => void
  page: (name: string) => void
  track: (eventName: Event["name"], properties?: Event["properties"]) => void
}

interface ProviderProps {
  children: JSX.Element
}

const SegmentContext = createContext<ContextProps>({
  load: () => {},
  identify: () => {},
  page: () => {},
  track: () => {},
})

interface Props {
  children: React.ReactNode
}

const SegmentProviderInner: React.FC<Props> = ({ children }) => {
  const load = () => {
    if (window.analytics.load) window.analytics.load(SEGMENT_WRITE_KEY)
  }
  const identify = (userId: UserData["id"], traits: any) => {
    window.analytics.identify(userId, traits)
  }

  const page = (name: string) => {
    window.analytics.page(name)
  }
  const track = (name: string, properties: any) => {
    window.analytics.track(name, properties)
  }

  return (
    <SegmentContext.Provider
      value={{
        load,
        identify,
        page,
        track,
      }}
    >
      {children}
    </SegmentContext.Provider>
  )
}

export const SegmentProvider: React.FunctionComponent<ProviderProps> = ({
  children,
}) => {
  return (
    <Segment writeKey={SEGMENT_WRITE_KEY}>
      <SegmentProviderInner>{children}</SegmentProviderInner>
    </Segment>
  )
}

export const useSegment = (): ContextProps => useContext(SegmentContext)
