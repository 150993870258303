import { useNavigate } from "react-router-dom"
import { useSegment } from "../../../context/SegmentContext"
import Background from "../../components/Wrapper/Background"
import AskThemAnything from "./AskThemAnythingBlock"
import DreamBlock from "./DreamBlock"
import HomeHeroBlock from "./HomeHeroBlock"
import FuzzletsBlock from "./FuzzletsBlock"
import LivingCharactersBlock from "./LivingCharactersBlock"
import MinistudioStory from "./MinistudioStory"
import SpaceshipBlock from "./SpaceshipBlock"
import { IconButton } from "@chakra-ui/react"
import { useState } from "react"
import { HamburgerIcon } from "@chakra-ui/icons"
import BurgerMenu from "../../components/Popup/BurgerMenu"

export default function Home() {
  const navigate = useNavigate()
  const { track } = useSegment()
  const [open, setOpen] = useState(false)

  return (
    <Background overflowY={"auto"}>
      <IconButton
        aria-label="Open"
        my="auto"
        ml="0.5rem"
        onClick={() => setOpen(true)}
        variant="ghostWhite"
        fontSize="40px"
        position="fixed"
        top="2rem"
        right="1rem"
        bg="#0000007f"
        borderRadius="12px"
        height="fit-content"
        padding="8px 8px"
        zIndex={10}
      >
        <HamburgerIcon />
      </IconButton>
      <BurgerMenu onClose={() => setOpen(false)} open={open} />

      <FuzzletsBlock
        onKickstarter={() => {
          track("Kickstarter")
          window.location.href = "https://www.kickstarter.com/projects/ministudio/fuzzlets-create-your-unique-personalized-card-game"
        }}
      />
      <HomeHeroBlock
        onLogin={() => {
          navigate("/login")
          track("Try Our App")
        }}
        onSignUp={() => {
          navigate("/sign-up")
          track("Try Our App")
        }}
      />
      <AskThemAnything onClick={() => navigate("/sign-up")} />
      <DreamBlock onClick={() => navigate("/sign-up")} />
      <SpaceshipBlock onClick={() => navigate("/sign-up")} />
      <LivingCharactersBlock
        onClickMission={() => navigate("/mission")}
        onClickPrivacy={() => navigate("/privacy")}
      />
      <MinistudioStory />
    </Background>
  )
}
